import React from 'react'
import {
   RegisterFlow,
   RegisterIntro,
} from '../../../../components/views/layout'
import { Seo } from '../../../../components/common'
import { useRegister } from '../../../../hooks'
import { registerFields } from '../../../../common/utils/register'

import * as styles from '../trial.module.scss'

const VerifyPage = () => {
   const { formState } = useRegister()

   return (
      <RegisterFlow title="Step 3">
         <Seo title="Register - Step 3" />

         <RegisterIntro>
            Almost done! We've just sent an email to
            <b> {formState[registerFields.EMAIL]}</b> to verify your account.
         </RegisterIntro>
         <RegisterIntro>
            Simply click the button in the email to complete your <br />
            sign up.
         </RegisterIntro>
         <div className={styles.textContainer}>
            <div>No email? Check your spam folder.</div>
            <div>Need help? Call (866) 768-TOOL option 2.</div>
         </div>
      </RegisterFlow>
   )
}

export default VerifyPage
